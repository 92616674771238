.svg-defs,
.svg-defs svg {
    position: absolute;
    width: 0;
    height: 0;
}

.align_left {
	float: left;
	margin-right: $gutter;
}

.align_right {
	float: right;
	margin-left: $gutter;
}

.clearfix:after {
	content: "";
	display: table;
	clear: both;
}