html {
	font-family: Fauna One;
	font-size: 87.5%;
	line-height: $line-height;

	@include media('>sm') {
		font-size: 100%;
	}

	@include media('>lg') {
		// font-size: 106.25%;
	}
}


a {
	color: $c-link;

	&:hover {
		color: $c-link-hover;
	}
}

p {
	margin-top: $line-height / 2;
	margin-bottom: $line-height / 2;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
	margin-top: $line-height;
	margin-bottom: $line-height / 2;
}

h1, h2, h3 {
	font-size: 1.25rem;

	@include media('>sm') {
		font-size: 1.5rem;
		line-height: $line-height * 1.5;
	}

	@include media('>lg') {
		font-size: 1.6rem;
	}
}

h4, h5, h6 {
	font-size: 1.1rem;

	@include media('>sm') {
		font-size: 1.2rem;
	}
}

html:not(.template-blocks-page) h1 {
	font-size: 1.6rem;
	margin: $line-height 0;

	@include media('>sm') {
		font-size: 1.8rem;
	}

	@include media('>lg') {
		font-size: 2rem;
	}
}