.page-footer {
	text-align: center;
}

.footer-nav {
	padding: $gutter;

	.footer-nav-title {
		text-align: center;
	}

	@include media('>sm') {
		display: none;
	}

	ul {
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		margin: $line-height * -.25;
	}

	.nav-item {
		margin: $line-height * .25;
		background: $c-bg-light;
		box-shadow: $c-box-shadow;
		border-radius: $radius;
		max-width: calc(50% - #{($line-height / 2)});
		flex: 1 1 calc(50% - #{($line-height / 2)});
		text-align: center;

		.nav-link {
			display: block;
			padding: $line-height * .25 $line-height * .5;
			text-decoration: none;
			border-radius: $radius;

			&:hover,
			&:focus,
			&.current {
				background-size: 100% 0.4em;
			}
		}
	}
}