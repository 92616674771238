.section-fullwidth {
	max-width: $text-width;
	margin: auto;

	.section-title {
		text-align: center;
	}

	.section-body {
	}
}