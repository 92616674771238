.gallery {
	margin: $line-height * -0.5 - $radius;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	&[data-pswp-uid] .gallery-figure,
	&[data-pswp-uid] .gallery-figure a {
		cursor: pointer;
		cursor: zoom-in;
		cursor: -webkit-zoom-in;
	}
}

.gallery-figure {
	@extend .image-box;
	margin: $line-height / 4;
	max-width: calc(50% - #{($line-height / 2)});
	flex: 1 1 auto;

	@include media('>sm') {
		max-width: calc(33% - #{($line-height / 2)});
	}

	@include media('>md') {
		max-width: calc(25% - #{($line-height / 2))});
	}

	figcaption {
		font-size: 0.875em;
		margin: 0.1em 0;
		text-align: center;
		line-height: $line-height;
		max-height: $line-height * 2;
		overflow: hidden;
	}
}