.page-section {
	max-width: $content-width;
	margin: calc(#{($gutter * 2)} + 2vw) auto;
	padding-left: $gutter;
	padding-right: $gutter;
}

.section-body {
	@extend .clearfix;
}

.section-figure {
	@extend .image-box;
}

@include media('>sm') {
	.section-title {
		margin-top: 0;
	}
}