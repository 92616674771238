.section-text-image {
	align-items: center;
	justify-content: space-between;

	@include media('>sm') {
		.section-figure {
			width: calc(50% - #{($gutter - $radius * 2)});
			order: 1;
			align-self: flex-start;
			flex: 0 0 calc(50% - #{($gutter - $radius * 2)});

			& + .section-text {
				order: 1;
				flex: 0 0 calc(50% - #{($gutter)});

				.page-section:nth-child(even) & {
					order: 0;
				}
			}
		}

		.section-text {
			max-width: $text-width;
		}
	}
}