.section-boxed {
	display: flex;

	.section-container {
		margin-left: auto;
		margin-right: auto;
		flex: 0 1 auto;
		padding: $gutter;
		max-width: $text-width;
		background: $c-bg-light;
		box-shadow: $c-box-shadow;
		border-radius: $radius;

		@include media('>sm') {
			padding: $gutter $gutter * 2;
		}
	}
}