.main-header .nav {
	width: 100%;
	top: 0;
	left: 0;
	position: fixed;
	max-height: 100vh;
	overflow: hidden;
	z-index: 100;
	padding-top: 2.75em;
    padding-bottom: 0.5em;
	background-color: $c-bg-light;
	box-shadow: 0 1px 4px rgba(0,0,0,0.06);
	transform: translateY(-100%);
	transition: transform 0.25s ease-in-out;

	@include media('>sm') {
		transform: translateY(0%);
		transition: none;
		padding-top: 0;
    	padding-bottom: 0;
		position: relative;
	}

	&.open {
		transform: translateY(0);
	}
}

.nav-items {
	align-items: baseline;
	justify-content: space-between;
	list-style: none;
	padding: 0;
	margin: 0;
	border-top: 1px solid #dfddd7;

	@include media('>sm') {
		padding: 0 $gutter / 2;
		border-top: none;
	}
}

.nav-link {
	padding: 0 $gutter;
	display: block;
	color: $c-text;
	text-decoration: none;
	line-height: 2.7;

	@include media('>sm') {
		padding: 0 $gutter / 2;
	}

	@include media('>md') {
		font-size: 1.2em;
	}

	@include media('>lg') {
		// font-size: 1.1em;
	}

	&:hover,
	&:focus,
	&.current {
		background-image: linear-gradient(-90deg, #F5611F 0%, #F46E0A 100%);
		background-size: 0.4em 100%;
		background-repeat: no-repeat;
		background-position: bottom left;

		@include media('>sm') {
			background-size: 100% 0.3em;
		}
	}

	&.link-contact {
		@extend .button;
		background-size: 100% 100%;
		background-position: 0 0;
		background-repeat: repeat;
		margin: 0.2em $gutter;

		&:hover,
		&:focus {
			background-size: 100% 100%;
		}

		@include media('<sm') {
			display: block;
		}
	}
}
