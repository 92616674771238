%wood-bar {
	content: '';
	position: absolute;
	background-color: $c-wood;
	background-image: url('../img/birch.jpg');
	border: 1px solid $c-wood-border;
	border-radius: 2px;
	box-shadow: $c-wood-shadow;
}

.speelhuis {
	display: flex;
	position: relative;
	max-width: $max-width;
	align-items: flex-end;
	padding-top: 1em;
	padding-bottom: 0em;
	margin: auto;
	font-size: 0.5rem;

	@include media(">md") {
		font-size: 1rem;
	}

	@include media(">lg") {
		// font-size: 0.888889rem;
	}

	@include media(">sm") {
		font-size: 0.75rem;

		.hekwerk-left {
			display: initial;
		}

		.potlood:nth-child(1n+7) {
			display: none;
		}
	}

	&::after {
		@extend %wood-bar;
		width: calc(100% + 4px);
		height: 1em;
		left: -2px;
		right: -2px;
		bottom: 0;
		z-index: 20;
	}

	.speelhuis-huis {
		flex: 0 1 22.5em;
		position: relative;
		z-index: 10;
		margin: 0 -0.2em 0.8em;
		filter: drop-shadow($c-wood-shadow);

		> svg {
			width: 22.50em;
			height: 23.12em;
			vertical-align: middle;
		}
	}

	.hekwerk {
		flex: 0 1 100%;
		position: relative;
		margin: 1em 0;

		&::before,
		&::after {
			@extend %wood-bar;
			width: calc(100% + 4px);
			left: -2px;
			right: -2px;
			height: 1em;
			bottom: 1em;
			z-index: 1;
		}

		&::before {
			top: 2.4em;
		}

		.hekwerk-paaltje {
			@extend %wood-bar;
			width: 1em;
			height: calc(100% - 1.5em);
			bottom: -0.5em;
			z-index: 0;
		}
	}

	.potloden {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-around;
		flex-wrap: wrap;
		position: relative;
		overflow: hidden;
		max-height: 12.5 + 0.8em;
		z-index: 5;
		padding: 0;

		.potlood {
			margin: 0.4em;
			flex: 0 0 auto;
			filter: drop-shadow( 1px 1px 1px rgba(0,0,0,0.1) );
			width: 2.8125em;
			height: 12.5em;
		}

		.colour-1 { color: #e93e00; }
		.colour-2 { color: #ffcd00; }
		.colour-3 { color: #f77e00; }
	}

	.hekwerk-right {
		padding-left: 0.4em;

		.hekwerk-paaltje {
			right: 0.2em;
		}
	}

	.hekwerk-left {
		display: none;
		padding-right: 0.4em;

		.hekwerk-paaltje {
			left: 0.2em;
		}
		.potloden {
			flex-direction: row-reverse;
		}
	}
}


.item--svg-clip-path-svg rect,
.item--svg-clip-path-svg image {
  clip-path: url(#clipping);
}