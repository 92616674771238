/**
 * Animated menu icon
 * Animates between hamburger icon and cross closing button
 * src: http://codepen.io/designcouch/pen/Atyop - #nav-icon3
 */

.menu-icon {
    width: 16px;
    height: 10px;
    margin: 1px 0;
    position: relative;
    display: inline-block;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: currentColor;
        border-radius: .1rem;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: transform .25s ease-in-out, top .25s ease-in-out, left .25s ease-in-out, width .25s ease-in-out;

        // Hamburger icon
        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: 50%;
        }

        &:nth-child(4) {
            top: 100%;
        }

        // Turn to cross on .open
        .open & {
            &:nth-child(1) {
                top: 50%;
                width: 0%;
                left: 50%;
            }

            &:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: 50%;
                width: 0%;
                left: 50%;
            }
        }
    }
}