.section-gallery {
	align-items: center;
	justify-content: space-between;

	.section-text {
		max-width: $text-width;
		margin: $line-height auto;
		width: 100%;
		flex: 1 1 100%;
	}

	@include media('>sm') {
		.section-figure {
			width: calc(50% - #{($gutter - $radius * 2)});
			order: 1;
			align-self: flex-start;
			flex: 0 0 calc(50% - #{($gutter - $radius * 2)});
		}

		.section-text {
			order: 1;
			flex: 0 0 calc(50% - #{($gutter)});

			.page-section:nth-child(even) & {
				order: 0;
			}
		}
	}
}