$img-path: "../img/";

$c-text: #222;
$c-link: #db2504;
$c-link-hover: lighten($c-link, 12%);
$c-bg-light: #fefbf3;
$c-wood: #f8ebc8;
$c-wood-border: #d9cfb9;
$c-wood-shadow: 1px 1px 1px rgba(0,0,0,0.1);
$c-box-shadow: 0 1px 4px 0 rgba(0,0,0,0.06);


$breakpoints: (
	xs: 0,
	sm: 34.25em,
	md: 48.00em,
	lg: 62.00em,
	xl: 75.00em
);

$max-width: #{(1200 / 16)}rem;
$content-width: #{(1000 / 16)}rem;
$text-width: 72ch;
$line-height: 1.44rem;
$gutter: 1.2rem;
$radius: 0.4rem;