
figure {
	margin: $line-height;
}

.image-box {
	margin: $radius * -1;
	padding: $radius;
	background: $c-bg-light;
	box-shadow: $c-box-shadow;
	border-radius: $radius;

	img {
		max-width: 100%;
		vertical-align: middle;
		border-radius: $radius / 2;
	}
}

img.align_left,
img.align_right {
	max-width: 45%;
	vertical-align: middle;
	border: $radius solid $c-bg-light;
	border-radius: $radius / 2;
	box-shadow: $c-box-shadow;
}