.button {
	font-size: 1.05em;
	padding: 0.1em 0.6em;
	line-height: 1.7;
	display: inline-block;
	color: white;
	text-decoration: none;
	background-image: linear-gradient(-225deg, #5894E2 0%, #4793E5 100%);
	border: 1px solid #366DAC;
	box-shadow: 1px 1px 1px rgba(0,0,0,0.05), inset 1px 1px 1px rgba(255,255,255,0.05);
	border-radius: 4px;
	text-shadow: 0 -1px 0 rgba(0,0,0,0.1);
	text-align: center;


	&:hover,
	&:focus {
		color: white;
		border-color: #1f4c80;
		background-image: linear-gradient(-225deg, #538EDB 0%, #3E83CE 100%);
	}
}