.mobile-nav-toggle {
	top: 0;
	right: 0;
	padding: $gutter / 2 $gutter;
	display: flex;
	position: absolute;
	position: fixed;
	z-index: 120;
	text-decoration: none;
	color: $c-text;
	align-items: center;
	width: 6.8em;
    line-height: 1.6;
	justify-content: space-between;
	transition: width 0.25s;
	background: rgba($c-bg-light, 0.9);
	border-radius: 0 0 0 $radius;
	box-shadow: $c-box-shadow;

	@include media('>sm') {
		display: none;
	}

	&.open {
		width: 100%;
	}

	.menu-icon {
		// float: right;
	}
}