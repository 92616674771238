.masthead {
	width: 100%;
}

.masthead-homepage-link {
	left: 0;
	right: 0;
	bottom: 0.6em;
	position: absolute;
	font-size: 2.5em;
	text-align: center;

	@include media(">md") {
		bottom: 1.15em;
		font-size: 2.2em;
	}

	.button {
		color: $c-text;
		background: rgba($c-bg-light, 0.65);
		border: rgba($c-text, 0.12) solid 1px;

		&:hover {
			color: $c-text;
			background: rgba($c-bg-light, 0.85);
			border: rgba($c-text, 0.2) solid 1px;
		}
	}
}