html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body {
	padding: 0 0.4rem;
	background: #baddf3 url(../img/blue-paint-pattern.jpg) 500px 500px center center;
	background-image: radial-gradient(circle, rgba(255, 254, 210, 0.4) 0%, rgba(210, 243, 255, 0) 92%), url('../img/blue-paint-pattern.jpg');
	background-size: 1200px 1200px, 500px 500px;
	background-position: center -300px, center center;
}

.page-container {
	margin: 0 auto $gutter;
	padding-top: $gutter / 2;
	padding-bottom: $gutter;
	width: 100%;
	max-width: $max-width;
	background-color: $c-wood;
	background-image: url(../img/birch-light.jpg);
	background-position: center center;
	background-size: 300px 300px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.1);
	border-radius: 0 0 0.5rem 0.5rem;
}
