@include media('>sm') {
	.row {
		display: flex;
		max-width: $content-width;
		margin: auto;
	}
}

.column {
	flex: 1 1 auto;
	padding: $gutter / 2 $gutter;

	.row {
		margin-left: -$gutter;
		margin-right: -$gutter;
	}
}