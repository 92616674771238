.section-basic {
    display: flex;
    flex-direction: column;
    align-items: center;

    .section-figure {
    	margin-bottom: $line-height / 2;
    }

	.section-text {
		max-width: $text-width;
		margin: auto;
		width: 100%;
		flex: 1 1 100%;
	}

	.section-title {
		font-size: 1.6rem;
    	margin: $line-height 0;

		@include media('>sm') {
			font-size: 1.8rem;
		}

		@include media('>lg') {
			font-size: 2rem;
		}
	}
}